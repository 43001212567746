<template>
  <section class="company-splash">
    <md-snackbar
      class="top bg-error"
      md-position="center"
      :md-active.sync="showError"
      md-persistent
    >
      <span>{{ $t(error) }}</span>
    </md-snackbar>

    <md-snackbar
      class="top"
      md-position="center"
      :md-active.sync="showNotice"
      md-persistent
    >
      <span>{{ $t(notice) }}</span>
    </md-snackbar>

    <div class="content">
      <p class="md-title">{{ $t('auth_fetch_credentials') }}</p>
      <img class="company-dialog__icon" :src="iconLoading" alt="iconLoading" />
      <md-progress-bar v-if="loading" md-mode="indeterminate" />
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import router from '../../plugins/router'
import iconLoading from '../../assets/images/icon_loading.svg'

export default {
  name: 'CompanySplash',

  data() {
    return {
      showError: false,
      showNotice: false,
      iconLoading,
    }
  },

  computed: mapState(['loading', 'error', 'notice', 'company']),

  watch: {
    error() {
      if (this.error === 'NOT_FOUND' && !this.companyId) {
        router.push('/404')
      } else {
        this.showError = !!this.error
      }
    },

    notice() {
      this.showNotice = !!this.notice
    },
  },

  async created() {
    const { companyId, slug } = router.currentRoute.params

    if (companyId) {
      const { id, slug, slugId } = await this.getCompanyById(companyId)
      return router.push({
        name: 'company',
        params: { companyId: id, slug, slugId },
      })
    }

    await this.getCompanyBySlug({ slug, id: companyId })

    router.push({
      name: 'company',
      params: { slug, slugId: this.company.slugId },
    })
  },

  methods: mapActions(['getCompanyById', 'getCompanyBySlug']),
}
</script>

<style>
.company-splash {
  height: 78vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-splash .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 25%;
}

@media (max-width: 768px) {
  .company-splash .content {
    width: 80%;
  }
}
</style>
